<template>
  <tr>
    <td>{{ rowNumber }}</td>
    <td>{{ data.createdAt | date('DD/MM/YYYY') }}</td>
    <td>{{ data.borrower.name }}</td>
    <td>{{ data.servicesAmount | currency }}</td>
    <td>
      <span
        class="label label-rounded"
        :class="statusLabelClass"
      >{{ statusName }}</span>
    </td>
    <td class="text-right">
      <button
        class="btn btn-sm btn-action btn-icon btn-neutral mr-1"
        @click="$emit('open-detail', data)"
      ><fa-icon :icon="['fal', 'info-circle']" /></button>
      <button
        class="btn btn-sm btn-action btn-icon btn-gray mr-1"
        :class="{ loading: printing }"
        @click="print"
        :disabled="!isPrintable"
      ><fa-icon :icon="['fal', 'print']" /></button>
      <button
        class="btn btn-sm btn-action btn-icon btn-error tooltip"
        :class="{ loading: canceling }"
        data-tooltip="Cancelar"
        @click="askToCancel"
        :disabled="!isCancelable"
      ><fa-icon :icon="['fal', 'times']" /></button>
    </td>
  </tr>
</template>

<script>
import {
  getName as getStatusName,
  getLabelClass as getStatusLabelClass,
} from '@/components/invoice/statuses';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    rowNumber: {
      type: Number,
    },
  },
  data() {
    return {
      printing: false,
      canceling: false,
    };
  },
  computed: {
    statusName() {
      return getStatusName(this.data.status);
    },
    statusLabelClass() {
      return getStatusLabelClass(this.data.status);
    },
    isCancelable() {
      return this.data.status === 'ISSUED';
    },
    isPrintable() {
      return ['ISSUED', 'CANCELED'].includes(this.data.status);
    },
    canPrint() {
      return true;
    },
    canCancel() {
      return true;
    },
  },
  methods: {
    async print() {
      if (!this.canPrint) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (!this.isPrintable || this.printing) return;

      this.printing = true;
      try {
        await this.$file.print(`/invoices/${this.data.id}/print`);
      } catch (e) {
        this.$toast.error(e);
      }
      this.printing = false;
    },
    async cancel() {
      if (!this.isCancelable || this.canceling) return;

      this.canceling = true;
      try {
        const { data } = await this.$http.post(`/invoices/${this.data.id}/cancel`);
        this.$toast.success('Solicitação de cancelamento enviada');
        this.$emit('cancel', data);
      } catch (e) {
        this.$toast.error(e);
      }
      this.canceling = false;
    },
    askToCancel() {
      if (!this.canCancel) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente <b>cancelar</b> esta nota fiscal?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Sim, cancelar',
            classes: 'btn-error mr-2',
            click: (close) => {
              close();
              this.cancel();
            },
          },
          { label: 'Sair' },
        ],
      });
    },
  },
};
</script>
