<template>
  <div class="page-container invoices-page">
    <portal to="page-name">Notas fiscais</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Notas fiscais</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div
            class="column col-4 col-md-12 col-sm-12 form-group"
            :class="{'has-error': $v.filter.startDate.$error || $v.filter.endDate.$error}"
          >
            <label class="form-label">Período</label>
            <div class="input-group">
              <dx-dropdown
                class="input-group-addon"
                :items="periodOptions"
                label="text"
                @select="setPeriod"
                direction="left"
              ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
              <dx-input-date
                id="start-date"
                class="form-input text-center"
                v-model="filter.startDate"
                @change="changedStartDate"
                @blur="$v.filter.startDate.$touch()"
              />
              <dx-input-date
                id="end-date"
                class="form-input text-center"
                v-model="filter.endDate"
                @change="changedEndDate"
                @blur="$v.filter.endDate.$touch()"
              />
            </div>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label for="status" class="form-label">Status</label>
            <select id="status" class="form-select" v-model="filter.status">
              <option value="">Todos os status</option>
              <option
                v-for="(text, value) in statuses"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
          </div>
          <div class="column col-sm-12 form-group">
            <label for="search" class="form-label">Tomador</label>
            <div class="input-group">
              <input
                type="text"
                id="search"
                class="form-input"
                v-model="filter.name"
                maxlength="100"
                autocomplete="nope"
                placeholder="Nome"
              >
              <button
                class="btn btn-neutral btn-action input-group-btn btn-icon"
                @click="reload"
                tabindex="-1"
              ><fa-icon :icon="['fal', 'search']"/></button>
            </div>
          </div>
          <div class="column col-auto col-sm-12 mb-2" style="align-self: flex-end">
            <button
              class="btn btn-primary btn-icon btn-icon-left btn-block"
              @click="openCreate"
            ><fa-icon :icon="['fal', 'plus']"/> Nova NFe</button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Notas fiscais</p>
            <p class="empty-subtitle">
              Nenhuma nota fiscal encontrada. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Data</th>
              <th>Tomador</th>
              <th>Valor</th>
              <th style="width: 7rem">Situação</th>
              <th style="width: 4.5rem"></th>
            </tr>
            </thead>
            <tbody>
            <table-row
              v-for="(item, i) in data.items"
              :data="item"
              :row-number="i + 1"
              :key="i"
              @open-detail="openDetail"
              @cancel="onCancel"
            />
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>

    <invoice-create
      v-if="modalCreate.show"
      :show="modalCreate.show"
      @close="modalCreate.show = false"
    />

    <invoice-detail
      v-if="modalDetail.show"
      :id="modalDetail.data.id"
      :show="modalDetail.show"
      @close="modalDetail.show = false"
    />
  </div>
</template>

<script>
// import { PRINT_BUDGET, DELETE_BUDGET } from '@/data/actions/modules/financial';
import moment from 'moment/moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import { parsePeriod } from '@/helpers/date';
import InvoiceCreate from '@/components/invoice/InvoiceCreate.vue';
import InvoiceDetail from '@/components/invoice/InvoiceDetail.vue';
import { statuses } from '@/components/invoice/statuses';
import TableRow from './TableRow.vue';

export default {
  components: {
    InvoiceCreate,
    InvoiceDetail,
    TableRow,
  },
  data() {
    return {
      showModal: false,
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      statuses,
      filter: {
        status: '',
        name: '',
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
      periodOptions: [
        { value: 'today', text: 'Hoje' },
        { value: 'week', text: 'Esta semana' },
        { value: 'month', text: 'Este mês' },
        { value: 'd:-30', text: 'Últimos 30 dias' },
        { value: 'd:-60', text: 'Últimos 60 dias' },
        { value: 'd:-90', text: 'Últimos 90 dias' },
      ],
      modalCreate: {
        show: false,
      },
      modalDetail: {
        show: false,
        data: null,
      },
    };
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => this.reload(), 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    async load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        dateField: 'created',
      };

      if (this.filter.status) {
        params.status = this.filter.status;
      }

      if (this.filter.name.trim().length > 0) {
        params.name = this.filter.name;
      }

      try {
        const { data } = await this.$http.get('/invoices', { params });
        this.data.hasMore = data.hasMore;
        this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
      this.loadingMore = false;
    },
    onCancel(item) {
      const found = this.data.items.find(({ id }) => id === item.id);
      found.status = item.status;
    },
    openDetail(item) {
      this.modalDetail.data = item;
      this.modalDetail.show = true;
    },
    openCreate() {
      this.modalCreate.show = true;
    },
    setPeriod({ value }) {
      const { start, end } = parsePeriod(value);
      this.filter.startDate = start;
      this.filter.endDate = end;
    },
    changedStartDate() {
      if (!this.filter.startDate) {
        this.filter.startDate = moment().format('YYYY-MM-DD');
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }
    },
    changedEndDate() {
      if (!this.filter.startDate && !this.filter.endDate) {
        this.filter.endDate = moment().format('YYYY-MM-DD');
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }
      if (!this.filter.endDate) {
        this.filter.endDate = this.filter.startDate;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
</style>
